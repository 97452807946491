<template>
    <div>
        <router-view :magbot="magbot" />
    </div>
</template>

<script>
export default {
    data(){
        return{
            magbot:''
        }
    },
    mounted(){
    },
    methods:{
    }
}
</script>

<style lang="scss">
.glo-nav{
    height: 3rem;
    line-height: 3rem;
    text-align: center;
}
.media .van-loading{
    padding-bottom: 20px;
}
.cell-custom{
    .van-cell{justify-content: space-between !important;}
    .van-cell__title, .van-cell__value{
        flex: inherit !important;
    }
    .van-cell__title{
        width: 30% !important;
    }
    .van-cell__value{
        width: 70% !important;
    }
}
// .suspension{
//     display: none;
//     position: fixed;
//     bottom: 12%;
//     right: 4%;
//     width: 55px;
//     height: 55px;
//     border: 1px solid #eb7445;
//     border-radius: 50%;
//     font-size: .85rem;
//     background-color: #eb7445;
//     color: white;
//     z-index: 999;
//     box-shadow:2px 2px 4px #989898;
//     div:first-child{
//         padding-top: 15%;
//     }
// }
// .position-bottom{position: fixed;bottom: 8%;width: 100%;}
</style>